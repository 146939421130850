const viteEnvType: "pro" | "stg" | "dev" = (import.meta as any).env.VITE_ENV_TYPE ?? "dev"

export default {
  BASE_URL: import.meta.env.VITE_APP_BACKEND_URL,
  INTERCOM_APP_ID: "ky15yid0",
  ZENDESK_APP_ID: "6eeecb3e-4106-4d67-9456-9db85e5f4351",
  GMAPS_KEY: "AIzaSyCrUF4g1DQ78Rs_wurS4WvQXuZigEOPEhs",
  FROALA_EDITOR_KEY: "te1C2sE6D6F6G5B4H3jC1QUd1Xd1OZJ1ABVJRDRNGGUE1ITrE1D4A3A10B1B6D6B1E4H3==",
  PUSHER_BEAMS_INSTANCE_ID:
    viteEnvType === "dev" ? "3034bd84-8c6a-4619-a47e-a3d58a70da98" : "85692a1e-4dff-471f-85ee-d19b36b3eb70",
  ENV_TYPE: viteEnvType,
}
